<template>
  <div>
    <core-page-title />

    <v-container>
      <v-row>
        <v-col>
          <btn-back to="/activity-branches" />
        </v-col>
      </v-row>

      <activity-branches-fields
        :loading-save="loading"
        :form-validation="formValidation"
        @save="save"
      />
    </v-container>
  </div>
</template>

<script>
  import activityBranchesApi from '@/api/activity-branches'

  export default {

    components: {
      ActivityBranchesFields: () => import('./ActivityBranchesFields'),
    },

    data () {
      return {
        loading: false,
        formValidation: {},
      }
    },

    methods: {
      async save (activityBranch) {
        this.loading = true
        this.formValidation = {}

        try {
          await activityBranchesApi.insert(activityBranch)

          this.$router.push('/activity-branches', () => this.$snackbar.show({ color: 'success', message: this.$messages._('new_success') }))
        } catch (e) {
          if (e.response.status === 422) {
            this.formValidation = this.$apiError.fieldsValidation(e)
            return
          }

          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
          return
        } finally {
          this.loading = false
        }
      },
    },
  }
</script>
